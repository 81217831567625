@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 10;
  }
}

.container {
  width: 100%;
  margin-top: 80px;

  @media (max-width: 1700px) {
    padding: 0 0 0 80px;
  }

  @media (max-width: 768px) {
    margin-top: 34px;
  }

  @media (max-width: 1024px) {
    padding: 0 24px !important;
  }

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (min-width: 1700px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1581px;
    padding: 0;
  }
}

.searchRow {
  display: grid;
  grid-template-columns: 48% 52%;
  gap: 75px;
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  /* display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  } */
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  /* padding: 0 24px 24px 24px; */

  @media (--viewportLarge) {
    /* padding: 0 36px 36px 36px; */
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  margin-top: 100px;
}

.mainWrapper {
  background-color: #fff;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  /* width: 100vw; */
  height: 100vh;

  & .canvas {
    width: 100%;
  }
}

.mapBox {
  position: relative;

  & .searchInput {
    position: absolute;
    z-index: 9;
    width: 628px;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    background: #ffffff;
    border: 0.5px solid #939393;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
      0px 1px 8px rgb(0 0 0 / 20%);
    border-radius: 4px;
  }
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
}

.ais-SearchBox {
  margin: 10px 0;
}

.ais-GeoSearch {
  padding-right: 0;
  padding-left: 0;
  height: 700px;
}

.marker {
  transform: translate(-50%, -100%) scale(0.5, 0.5);
}

.notLoginUserSearch {
  /* margin-top: 80px; */
}

.aisInstantSearch {
  display: flex;
  margin-bottom: 50px;
  gap: 74px;

  & .fullLeftPanel {
    width: 100% !important;
  }

  & .leftPanel {
    width: 50%;
    padding-top: 35px;

    @media (max-width: 768px) {
      padding: 0;
    }

    @media (max-width: 1300px) {
      width: 100%;
    }

    & .dividerLine {
      background-color: #e0e0e0;
      height: 1px;
      width: 100%;
    }

    & .paginatgionBottom {
      margin-top: 57px;
    }

    & .searchFilter {
      display: flex;
      gap: 20px;
      margin-bottom: 35px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      &>div {
        &:nth-child(1) {
          @media (max-width: 460px) {
            width: 100%;
          }
        }

        &:nth-child(2) {
          @media (max-width: 600px) {
            width: 75%;
          }
        }
      }

      & form {
        & button {
          &:nth-child(2) {
            /* left: 1.3rem; */

            & svg {
              width: 21px;
              height: 21px;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          background: #ffffff;
          height: 51px;
          border: 0.5px solid #939393;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          width: 362px;
          padding: 14px 14px 14px 44px !important;

          @media (max-width: 460px) {
            width: 100%;
          }
        }
      }

      & .filterBox {
        cursor: pointer;
        @media (max-width: 767px) {
          cursor: default;
        }
      }

      & .dateSelect {
        & select {
          background-position: 95% 17px;
          border: 0.5px solid #939393;
          border-radius: 4px;
          width: 289px;
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px;
        }
      }
    }

    & .filterClearButton {
      padding-bottom: 20px;

      & button {
        cursor: pointer;

        @media (max-width: 767px) {
          cursor: default;
        }

        background-color: #000000;
        color: #fff;
        border: 1px solid #3d5a81;
        border-radius: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        text-transform: capitalize;
        padding: 5px 10px;
      }
    }

    & .fullCardGrid {
      & ul {
        grid-template-columns: repeat(4, 1fr) !important;

        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr) !important;
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }

    & .cardsGrid {
      margin-top: 35px;

      & ul {
        display: grid;
        column-gap: 24px;
        row-gap: 45px;
        margin-top: 0;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 1300px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr) !important;
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }

        @media (max-width: 1500px) {
          column-gap: 34px;
          margin: 0;
        }

        & li {
          width: 50%;
          min-width: 100%;
          margin: 0;
          border: none;
          box-shadow: none;
          padding: 0;
          cursor: pointer;

          @media (max-width: 767px) {
            cursor: default;
          }
        }
      }

      & button {
        cursor: pointer;

        @media (max-width: 767px) {
          cursor: default;
        }

        background-color: #000000;
        color: #fff;
        border: 1px solid #3d5a81;
        border-radius: 2px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        text-transform: capitalize;
        padding: 5px 10px;
        margin-top: 18px;

        &:disabled {
          display: none;
        }
      }
    }

    & .clearFilter {
      margin: 10px 0;
    }

    & .selectCategoryData {
      & ul {
        margin-bottom: 34px;
        display: flex;
        flex-direction: row;
        scroll-snap-type: x mandatory;
        overflow-x: auto;
        -webkit-box-flex: 1;
        flex-grow: 1;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }
      }

      & :global(.ais-RefinementList-item--selected) {
        background: #fff8f2;
        border: 1px solid #4661ed;
        border-radius: 100px;
        transform: matrix(1, 0, 0, 1, 0, 0);

        & label {
          & span {
            color: #4661ed;
          }
        }
      }

      & :global(.ais-RefinementList-count) {
        display: none;
      }

      & li {
        background: #ffffff;
        border: 1px solid #212121;
        padding: 8px 20px;
        border-radius: 100px;
        margin-right: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #212121;
        min-width: fit-content;
        cursor: pointer;

        @media (max-width: 767px) {
          cursor: default;
        }

        & label {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 0;

          & input {
            display: none;
          }

          & span {
            &:nth-child(3) {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  & .rightPanel {
    width: 50%;
    position: relative;
    height: 100%;
    overflow: hidden;

    @media (max-width: 1300px) {
      display: none;
    }

    & .mapRightBar {
      position: sticky;
      right: 0;
      width: 100%;

      &>div {
        &>div {
          height: 100vh;
          width: 100%;

          &>div {
            &>div {
              &>div {
                &:nth-child(8) {
                  &>div {
                    right: 48px;
                    left: unset !important;
                    bottom: 98px;
                    top: unset !important;

                    &>div {
                      &>div {
                        box-shadow: 0px 4px 8px rgba(35, 38, 59, 0.25) !important;
                        border-radius: 8px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .rangeSliderBox {
      position: absolute;
      left: 43px;
      margin-right: auto;
      width: 575px;
      z-index: 9;
      bottom: 35px;
      background: #ffffff;
      box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
        0px 1px 8px rgb(0 0 0 / 20%);
      border-radius: 8px;
      padding: 22px 30px;

      & .sliderHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        & .radioName {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #212121;
        }
      }
    }

    & .searchMapInput {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 628px;
      z-index: 9;
      top: 35px;

      @media (max-width: 1500px) {
        width: 70%;
      }

      & form {
        & button {
          &:nth-child(2) {
            left: 1.3rem;
            background-image: url(../../assets/search-location.png);
            background-size: 17px;
            background-repeat: no-repeat;
            background-position: center;
            height: 30px;

            & svg {
              /* width: 21px;
              height: 21px; */
              display: none;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px 14px 14px 0;
          background: #ffffff;
          /* border: 0.5px solid #939393;
          box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
            0px 1px 8px rgb(0 0 0 / 20%); */
          border-radius: 4px;
        }
      }
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.mapToogleButton {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 1300px) {
    display: none;
  }

  & .mapLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #595858;
  }
}

.checkboxWrapper {
  cursor: pointer;

  @media (max-width: 767px) {
    cursor: default;
  }

  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #000000;
  background: rgb(0 0 0);

  @media (max-width: 768px) {

    /* height: 40px; */
    .breadcrumbsBox {
      &>div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
      }
    }
  }
}

.filtersList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  row-gap: 24px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }

  & .filterBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .labelName {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #4a4a4a;
    }

    & select {
      background-size: 12px;
      background-position: 97% 11px;
      border: 0.5px solid #939393;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #939393;
      padding: 5px 10px;

      /* width: 140px; */
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.bottomButton {
  margin-top: 20px;

  & .clearLink {
    & button {
      text-decoration: underline;
      color: #4661ed;
      cursor: pointer;

      @media (max-width: 767px) {
        cursor: default;
      }

      border: none;
    }
  }
}

.navLinksBox {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 29px;

  & nav {
    &>div {
      &:nth-child(7) {
        display: none;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(5) {
        display: none;
      }
    }

    /* background-color: red; */
    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.searchTabs {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 29px;
  display: flex;

  @media (max-width: 768px) {
    border: none;
    padding: 0;
    margin: 0;
  }

  & ul {
    display: flex;

    & :global(.ais-RefinementList-item--selected) {
      & span {
        font-weight: 700;
        color: #4661ed;
      }
    }

    & li {
      margin-right: 20px;

      & label {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #595858;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
      }

      & span {
        &:nth-child(3) {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          position: relative;
          display: flex;
          display: none;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & nav {
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    &>div {
      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    &>span {
      padding-left: 0;
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.checkboxWrapper {
  cursor: pointer;

  @media (max-width: 767px) {
    cursor: default;
  }

  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #56889f;
  background: #56889f;

  @media (max-width: 768px) {
    /* height: 40px; */
  }
}

.keywordFilter {
  position: relative;

  & button {
    background-color: #fff;
    border: none;
    cursor: pointer;

    @media (max-width: 767px) {
      cursor: default;
    }

    width: 38px;
    position: absolute;
    top: 26%;
    /* left: 24px; */
    /* transform: translate(-50%, -50%); */
    right: 12px;
  }
}

.radioButton {
  display: flex;
  gap: 10px;
  align-items: center;

  & input {
    width: 16px;
    box-shadow: none;
    height: 16px;
    padding: 0;
  }

  & label {
    padding: 0;
  }
}

.ais-RefinementList-count {
  display: none;
}