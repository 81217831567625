@import '../../styles/customMediaQueries.css';
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600;700;800&display=swap');
.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.completeBoxOuter {
}

.imagesFieldArray {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 0 0 24px 0;
}

.thumbnail {
  width: 100%;
  background-color: var(--colorGrey50);
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }

  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
@media (max-width: 767px) {
  cursor: default;
}
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--colorGrey300);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey300);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--colorGrey300);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--colorFail);
}

.arrayError {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorFail);
  width: 100%;
  clear: both;

  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.doneButton {
  /* display: inline-block; */
  align-items: center;
  margin-top: 64px;
  & button{
    color: #FFF;
text-align: center;
font-family: Kanit;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.18px;
  }
}

.submitButton {
  /* flex-shrink: 0; */
  /* margin-top: auto; */
  /* margin-bottom: 24px; */
  width: 422px;
  display: inline-block;

  @media (max-width: 550px) {
    width: 100%;
  }
}

.completeBox {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.25);
  max-width: 100%;

  @media (min-width: 1120px) {
    max-width: 1120px;
    margin: 93px auto;
  }

  & img {
    width: 100%;
  }
}

.mainHeading {
  text-align: center;
  padding: 30px 200px 69px 200px;

  @media (max-width: 1024px) {
    padding: 30px 50px;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }

  & .headingText {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px; /* 233.333% */
    margin: 0;
  }

  & .listInput {
    margin-top: 48px;
  }

  & .detailText {
    color: #000;
    text-align: center;
    font-family: Kanit;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px; /* 130% */
    margin: 0 auto;
    padding: 0;
    margin-top: 16px;
    max-width: 650px;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
